import React, { FC, useState } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { jsonTocsvDownloaderV2 } from '../../util/jsonTocsvDownloader';
import { sendToast } from '../../util';
interface ComponentProps {
    // todo conditioanly make onPrintClick optional based on disablePrintButton
    onPrintClick?: () => void;
    exportData?: {}[];
    preventDataFormat?: boolean;
    printLoading?: boolean;
    disablePrintButton?: boolean;
    exportFileName: string;
    disableExportButton?: boolean;
    isbasicRequests?: boolean;
    exportAll?: boolean
    fetchAll?: any
    communicationsPageStyles?: boolean,
    exportButtonTitle?: string,
}

const PrintExportIcons: FC<ComponentProps> = ({ exportData, onPrintClick, printLoading, disablePrintButton, exportFileName, preventDataFormat, isbasicRequests = false, exportAll = false, fetchAll, communicationsPageStyles = false, exportButtonTitle = undefined }) => {

    const [exportLoading, setExportLoading] = useState<boolean>(false);

    const handleExport = async () => {
        if (exportAll) {
            setExportLoading(true);
            const Data = fetchAll && await fetchAll();
            console.log({Data})
            let exportDataDeepCopy = [...Data];
            if (!exportDataDeepCopy.length) {
                sendToast("warn", "No data to export");
            } else {
                jsonTocsvDownloaderV2(exportDataDeepCopy, exportFileName, preventDataFormat);
                setExportLoading(false);
            }
        } else if (exportData) {
            let exportDataDeepCopy = [...exportData];
            if (!exportDataDeepCopy.length) {
                sendToast("warn", "No data to export");
            } else {
                jsonTocsvDownloaderV2(exportDataDeepCopy, exportFileName, preventDataFormat);
            }
        }
    };


    return (
        <div style={{ display: 'flex', alignItems: communicationsPageStyles ? undefined : 'center', gap: '10px', float: communicationsPageStyles ? 'right' : undefined }}>
            {!disablePrintButton ? (
                <Popup
                    trigger={
                        <Button
                            icon="print"
                            size="large"
                            onClick={(e) => {
                                e.preventDefault();
                                onPrintClick && onPrintClick();
                            }}
                            style={{ background: 'none', padding: 0, margin: 0 }}
                            loading={printLoading || false}
                        />
                    }
                    content="Print"
                    size="tiny"
                    type="button"
                />
            ) : (
                <></>
            )}
            {exportButtonTitle ? <Button
                icon={isbasicRequests ? 'cloud download' : 'download'}
                size="large"
                onClick={async (e) => {
                    e.preventDefault();
                    handleExport();
                }}
                loading={exportLoading || false}
            >{exportButtonTitle}</Button>
                :
                <Popup
                    trigger={
                        <Button
                            icon={isbasicRequests ? 'cloud download' : 'download'}
                            size="large"
                            onClick={async (e) => {
                                e.preventDefault();
                                handleExport();
                            }}
                            loading={exportLoading || false}
                            style={{ background: 'none', padding: 0, margin: 0 }}
                        />
                    }
                    content="Export"
                    size="tiny"
                    type="button"
                />
            }
        </div>
    );
};

export default PrintExportIcons;